.excalidraw {
  --color-primary-contrast-offset: #625ee0; // to offset Chubb illusion

  &.theme--dark {
    --color-primary-contrast-offset: #726dff; // to offset Chubb illusion
  }
  .layer-ui__wrapper .layer-ui__wrapper__footer-center {
    justify-content: flex-end;
    margin-top: auto;
    margin-bottom: auto;
    margin-inline-start: auto;
  }

  .encrypted-icon {
    border-radius: var(--space-factor);
    color: var(--color-primary);
    margin-top: auto;
    margin-bottom: auto;
    margin-inline-start: auto;
    margin-inline-end: 0.6em;

    svg {
      width: 1.2rem;
      height: 1.2rem;
    }
  }
}
.excalidraw-app.is-collaborating {
  [data-testid="clear-canvas-button"] {
    display: none;
  }
}

.plus-button {
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  border: 1px solid var(--color-primary);
  padding: 0.5rem 0.75rem;
  border-radius: var(--space-factor);
  color: var(--color-primary) !important;
  text-decoration: none !important;

  font-size: 0.75rem;
  box-sizing: border-box;
  height: var(--default-button-size);

  &:hover {
    background-color: var(--color-primary);
    color: white !important;
  }

  &:active {
    background-color: var(--color-primary-darker);
  }
}

.theme--dark {
  .plus-button {
    &:hover {
      color: black !important;
    }
  }
}
